import React from 'react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider, Global } from '@emotion/react'
import theme from './src/theme'
import globalStyles from './src/utils/globalStyles'
import { BrowserDatabase } from '@/utils/BrowserDatabase'
import { ONE_YEAR_IN_SECONDS } from '@/utils/BrowserDatabase/lifetime'
import { GATSBY_ROUTER } from '@/utils/BrowserDatabase/type'

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>
    <ThemeProvider theme={ theme }>
      <Global styles={ globalStyles } />
      {element}
    </ThemeProvider>
  </RecoilRoot>
)

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!window.__gatsbyRouter) {
    window.__gatsbyRouter = { location: '', prevLocation: '' }
  }

  window.__gatsbyRouter.location = location.pathname
  window.__gatsbyRouter.prevLocation = prevLocation ? prevLocation.pathname : null

  const data = BrowserDatabase.getItem(GATSBY_ROUTER) || []

  if (data) {
    data.push(location)
  }

  BrowserDatabase.setItem(data, GATSBY_ROUTER, ONE_YEAR_IN_SECONDS)
}
